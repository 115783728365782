
<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1>Settings</h1></div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
        title="Setting Updated Successfully"
        type="success"
        effect="dark"
    />
  </div>
  </el-col>
</el-row>

    <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
      <el-tabs type="border-card">
      <el-tab-pane 
          label="General"
        >
        <div class="panel panel-default">
		<div class="note-label">Note:- Data Showing in Contact Page, Subscribe Form, Header ( Website Name if main logo is missing ), Footer</div>
		<div class="note-label italic">Note:- Menu page ( head/header/footer ), DDFlisting/Listing Print View.</div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Website Name"
            prop="config_name"
            >
            <el-input
            v-model="data.config_name"
            type="text"
            autocomplete="off"
            />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
            <el-form-item
            label="Website Owner"
            prop="config_owner"
            >
            <el-input
            v-model="data.config_owner"
            type="text"
            autocomplete="off"
            />
            </el-form-item>
          </el-col>
        </el-row>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Address"
            prop="config_address"
            >
            <el-input
            v-model="data.config_address"
            type="text"
            autocomplete="off"
            />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
            <el-form-item
            label="Country"
            prop="config_country_id"
            >
            <el-select v-model="data.config_country_name" placeholder="Select Country">
              <el-option
              v-for="item in countries"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              @click="getStates(item.value)"
            ></el-option>
          </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Region / State"            
            prop="config_zone_id"
            >
             <el-select v-model="data.config_state_name"  placeholder="Select Region / State">
              <el-option              
              v-for="item in states"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click="getCities(item.value)"
            />
          </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
            <el-form-item
            label="City"
            prop="config_city"
            >
            <el-select v-model="data.config_city_name" placeholder="City">
              <el-option              
              v-for="item in cities"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Address 2"
            prop="config_address2"
            >

             <el-input
          v-model="data.config_address2"
          type="textarea"
        />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
            <el-form-item
            label="Address 3"
            prop="config_address3"
            >
             <el-input
          v-model="data.config_address3"
          type="textarea"
        />
            </el-form-item>
          </el-col>
        </el-row>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Language">
             <el-select v-model="data.config_language">
              <el-option              
              v-for="item in arraylanguage"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        </div>
      </el-tab-pane>
       <el-tab-pane 
          label="Contact"
        >
        <div class="panel panel-default">
		<div class="note-label">Note:- Data Showing in Contact Page, Subscribe Form, Header, Footer, website info module </div>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="E-Mail"
            prop="config_email"
            >
            <el-input
            v-model="data.config_email"
            type="text"
            />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
            <el-form-item
            label="Phone Number"
            prop="config_telephone"
            >
            <el-input
            v-model="data.config_telephone"
            type="text"
            />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Office-Phone"
            prop="config_officephone"
            >
            <el-input
            v-model="data.config_officephone"
            type="text"
            />
            </el-form-item>
          </el-col>
          
        </el-row>
        </div>
        </el-tab-pane>
         <el-tab-pane 
          label="Social Media"
        >
        <div class="panel panel-default">
		<div class="note-label">Note:- Data Showing in Header, Footer, website info module, property detail, blog detail </div>
		<div class="note-label italic">Note:- Menu page ( head/header/footer ), Property Detail Page.</div>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Skype Name"
            prop="config_skype"
            >
            <el-input
            v-model="data.config_skype"
            type="text"
            />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Twitter Name"
            prop="config_twitter"
            >
            <el-input
            v-model="data.config_twitter"
            type="text"
            />
            </el-form-item>
          </el-col>
         </el-row>
          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Facebook Name"
            prop="config_facebook"
            >
            <el-input
            v-model="data.config_facebook"
            type="text"
            />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="LinkedIn Name"
            prop="config_linkedin"
            >
            <el-input
            v-model="data.config_linkedin"
            type="text"
            />
            </el-form-item>
          </el-col>
         </el-row>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Instagram Name"
            prop="config_instagram"
            >
            <el-input
            v-model="data.config_instagram"
            type="text"
            />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Google"
            prop="config_google"
            >
            <el-input
            v-model="data.config_google"
            type="text"
            />
            </el-form-item>
          </el-col>
         </el-row>
          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Youtube"
            prop="config_youtube"
            >
            <el-input
            v-model="data.config_youtube"
            type="text"
            />
            </el-form-item>
          </el-col>
         </el-row>
        </div>
        </el-tab-pane>
        <el-tab-pane 
          label="Option"
        >
        <div class="panel panel-default">
		<div class="note-label">Note:- front end/ admin end all pagination, sort, listings page</div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Default Listings Per Page (Website)"
            prop="config_product_limit"
            >
            <el-input
            v-model="data.config_product_limit"
            type="text"
            />
            </el-form-item>
          </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="List Description Limit (Website)"
            prop="config_product_description_length"
            >
            <el-input
            v-model="data.config_product_description_length"
            type="text"
            />
            </el-form-item>
          </el-col>
         </el-row>
          <el-row :gutter="20">
             <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label=" Default Listings Per Page (Admin)"
            prop="config_limit_admin"
            >
            <el-input
            v-model="data.config_limit_admin"
            type="text"
            />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Property Sort By"
            prop="config_default_sortby"
            >
           <el-select
                  v-model="data.config_default_sortby" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in selectlistingsortby" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          
         </el-row>
            <el-row :gutter="20">
               <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="PropertyInfo PageViews limit"
            prop="config_propertyinfo_view_limit"
            >
            <el-input
            v-model="data.config_propertyinfo_view_limit"
            type="text"
            />
            </el-form-item>
          </el-col>

               <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Login Popup PreOpen Time (milliseconds)"
            prop="config_popup_preopen_time"
            >
            <el-input
            v-model="data.config_popup_preopen_time"
            type="text"
            />
            </el-form-item>
          </el-col>
            </el-row>
          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Hide Sold Property Price"
            >
            <el-radio v-model="data.config_hide_sold_price" label="yes">Yes</el-radio>
            <el-radio v-model="data.config_hide_sold_price" label="no">No</el-radio>
 
            </el-form-item>
          </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Customer Forced login if already Exists"
            >
            <el-radio v-model="data.config_customer_force_login" label="yes">Yes</el-radio>
            <el-radio v-model="data.config_customer_force_login" label="no">No</el-radio>
            </el-form-item>
          </el-col>
         </el-row>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Enable Mls® Board Listings"
            >
           
            <el-checkbox 
                v-model="data.config_ismlsenabled"
                value="y"
                />
            </el-form-item>
          </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Enable Mls® Subarea Listings"
            > 
            <el-checkbox 
                  v-model="data.config_mls_city_filter"
                  value="1"
                />
            </el-form-item>
          </el-col>
         </el-row>
             <el-row :gutter="20">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Share MLS® Listing on social Media"
            > 
            <el-checkbox 
                  v-model="data.config_post_mlslistings"
                  value="1"
                />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Show Listings with Sold"
            >
              <el-checkbox 
                  v-model="data.config_listings_with_sold"
                  value="1"
                />
            </el-form-item>
          </el-col>
         
         </el-row>
        </div>
        </el-tab-pane>
        <el-tab-pane 
          label="Server"
        >
        <div class="panel panel-default">
		<div class="note-label">Note:- listings, listings deail, contact page, comunity page</div>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Select Map Type"
            prop="config_map_type"
            >
            <select
                  v-model="data.config_map_type" 
                  class="selectstatus"
                >
                  <!--Add options when value is id-->
                  <option 
                    v-for="item in selectmaptype" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </option>
                </select>
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Google Map Api Key"
            prop="config_map_api"
            >
            <el-input
            v-model="data.config_map_api"
            type="text"
            />
            </el-form-item>
          </el-col>
         </el-row>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Use SSL"
            prop="config_secure"
            >
            <el-radio v-model="data.config_secure" label="1">Yes</el-radio>
            <el-radio v-model="data.config_secure" label="0">No</el-radio>
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Maintenance Mode"
            prop="config_maintenance"
            >
            <el-radio v-model="data.config_maintenance" label="1">Yes</el-radio>
            <el-radio v-model="data.config_maintenance" label="0">No</el-radio>
            </el-form-item>
          </el-col>
         </el-row>
        </div>
        </el-tab-pane>
         <el-tab-pane 
          label="Seo Info"
        >
        <div class="panel panel-default">
		<div class="note-label">Note:- Home page</div>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Meta Title"
            prop="config_meta_title"
            >
             <el-input
          v-model="data.config_meta_title"
          type="textarea"
        />
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Meta Keyword"
            prop="config_meta_keyword"
            >
            
             <el-input
          v-model="data.config_meta_keyword"
          type="textarea"
        />
            </el-form-item>
          </el-col>
         </el-row>
          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Meta Description"
            prop="config_meta_description"
            >
             <el-input
          v-model="data.config_meta_description"
          type="textarea"
        />
            </el-form-item>
          </el-col>
         
         </el-row>
        </div>
        </el-tab-pane>
         <el-tab-pane 
          label="Mail"
        >
        <div class="panel panel-default">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Mail Protocol"
            prop="config_mail_protocol"
            >
              <el-select
                  v-model="data.config_mail_protocol" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in MailProtoco" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Mail Parameters"
            prop="config_mail_parameter"
            >
            <el-input
          v-model="data.config_mail_parameter"
          type="text"
        />
            </el-form-item>
          </el-col>
         </el-row>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
           <el-form-item
            label="SMTP Hostname"
            prop="config_mail_smtp_hostname"
            >
            <el-input
          v-model="data.config_mail_smtp_hostname"
          type="text"
        />
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="SMTP Username"
            prop="config_mail_smtp_username"
            >
            <el-input
          v-model="data.config_mail_smtp_username"
          type="text"
        />
            </el-form-item>
          </el-col>
         </el-row>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
           <el-form-item
            label="SMTP Password"
            prop="config_mail_smtp_password"
            >
            <el-input
          v-model="data.config_mail_smtp_password"
          type="text"
        />
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="SMTP Port"
            prop="config_mail_smtp_port"
            >
            <el-input
          v-model="data.config_mail_smtp_port"
          type="text"
        />
            </el-form-item>
          </el-col>
         </el-row>

          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
           <el-form-item
            label="SMTP Timeout"
            prop="config_mail_smtp_timeout"
            >
            <el-input
          v-model="data.config_mail_smtp_timeout"
          type="text"
        />
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Additional Alert E-Mails"
            prop="config_mail_alert"
            >
            <el-input
          v-model="data.config_mail_alert"
          type="textarea"
        />
            </el-form-item>
          </el-col>
         </el-row>
        </div>
        </el-tab-pane>
        <el-tab-pane 
          label="Google"
        >
        <div class="panel panel-default">
		<div class="note-label">Note:- All form captcha Enabled/Disabled, Google Analytics Code Enabled/Disabled</div>
         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Google Analytics Code"
            prop="config_google_analytics"
            >
             <el-input
          v-model="data.config_google_analytics"
          type="textarea"
        />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Status"
            prop="config_google_analytics_status"
            >
            <el-select
                  v-model="data.config_google_analytics_status" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
         </el-row>
         <el-row :gutter="20">
             <el-col>
               <el-fieldset>CAPTCHA</el-fieldset>
             </el-col>
           </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Site key" 
                prop="config_google_captcha_public"
              >
                <el-input 
                  v-model="data.config_google_captcha_public" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Secret key" 
                prop="config_google_captcha_secret"
              >
                <el-input 
                  v-model="data.config_google_captcha_secret" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Invisible Captcha Site Key" 
                prop="config_google_invisiblecaptcha_public"
              >
                <el-input 
                  v-model="data.config_google_invisiblecaptcha_public" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Invisible Captcha Site Secret" 
                prop="config_google_invisiblecaptcha_secret"
              >
                <el-input 
                  v-model="data.config_google_invisiblecaptcha_secret" 
                  type="text" 
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Status" 
                prop="config_google_captcha_status"
              >
                 <el-select
                  v-model="data.config_google_captcha_status" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="More Info Form" 
                prop="config_captcha_more_info"
              >
              <el-select
                  v-model="data.config_captcha_more_info" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Rental Application Form" 
                prop="config_captcha_rental_form"
              >
                 <el-select
                  v-model="data.config_captcha_rental_form" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="Request a Showing Form" 
                prop="config_captcha_request_form"
              >
              <el-select
                  v-model="data.config_captcha_request_form" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Request for Quote Form" 
                prop="config_captcha_request_quote"
              >
                 <el-select
                  v-model="data.config_captcha_request_quote" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                label="My Home Worth Form" 
                prop="config_home_worth_form"
              >
              <el-select
                  v-model="data.config_home_worth_form" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item 
                label="Register Form" 
                prop="config_register_form"
              >
                 <el-select
                  v-model="data.config_register_form" 
                >
                  <!--Add options when value is id-->
                  <el-option 
                    v-for="item in analyticsstatus" 
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                  >
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>            
          </el-row>
        </div>
        </el-tab-pane>
       
        <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="align-left">
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button v-if="!DomainId" @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
      </div>
  </el-col>
  
</el-row>

</el-tabs>


    </el-form>
</template>
<script>
import SettingService from "@/api/setting.service";
import CountryService from "@/api/country.service";
import authHeader from '@/api/auth-header';
import TinyMce from "@/components/TinyMce";

  export default {
    components: {
      TinyMce
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter Name.'));
        } else {
          callback();
        }
      };
      var checkAddress = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter address'));
        } else {
          callback();
        }
      };
     
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        DomainId: this.$route.params.DomainId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
        countries: [],
        states:[],
        cities:[],
		analyticsstatus:[{
          value: '1',
          label: 'Enabled'
        }, {
          value: '0',
          label: 'Disabled'
        }],
    selectmaptype:[{
          value: 'leafletmap',
          label: 'Leaflet Map'
        }, {
          value: 'googlemap',
          label: 'Google Map'
        }],
     selectlistingsortby:[{
          value: 'default',
          label: 'Default'
        }, {
          value: 'available_new',
          label: 'Available New'
        }, {
          value: 'available_old',
          label: 'Available Old'
        }, {
          value: 'price_low_to_high',
          label: 'Price (Low > High)'
        }, {
          value: 'price_high_to_low',
          label: 'Price(High > Low'
        }, {
          value: 'date_old',
          label: 'Date (Old)'
        }, {
          value: 'date_new',
          label: 'Date (New)'
        }],
        MailProtoco:[{
          value: 'mail',
          label: 'Mail'
        }, {
          value: 'smtp',
          label: 'SMTP'
        }, {
          value: 'mailgun',
          label: 'Gmail'
        }, {
          value: 'sendgrid',
          label: 'Sendgrid'
        }],
        arraylanguage:[{
          value: 'en',
          label: 'English'
        }],
        data: {
          config_name: null,
          config_owner: null,
          config_address: null,
          config_country_id: null,
          config_country_name: null,
          config_zone_id: null,
          config_state_name:null,
          config_city: null,
          config_city_name:null,
          config_address2: null,
          config_address3: null,
          config_email: null,
          config_telephone: null,
          config_officephone: null,
          config_skype: null,
          config_twitter: null,
          config_facebook: null,
          config_linkedin: null,
          config_instagram: null,
          config_google: null,
          config_youtube: null,
          config_product_limit: '15',
          config_product_description_length: null,
          config_default_sortby: 'default',
          config_propertyinfo_view_limit: null,
          config_popup_preopen_time: null,
          config_hide_sold_price: 'no',
          config_customer_force_login: 'yes',
          config_ismlsenabled: null,
          config_mls_city_filter:null,
          config_listings_with_sold: null,
          config_map_api: null,
          config_map_type: null,
          config_secure: null,
          config_maintenance: null,
          config_meta_title: null,
          config_meta_description: null,
          config_meta_keyword: null,
          config_google_analytics: null,
          config_google_analytics_status: null,
          config_portrait:null,
          config_logo:null,
          config_websitelogo2:null,
           config_websitelogo3:null,
          config_officelogo:null,
          config_icon:null,
          config_sold_ribbon:null,
          config_openhouse_ribbon:null,
        },

        rules: {
          config_name: [
            { validator: checkName, trigger: 'blur' }
          ],
          config_address: [
            { validator: checkAddress, trigger: 'blur' }
          ]
        }
      };
    },
    watch: {
      data: function () {
        if(!this.data.config_country_name && this.data.config_country_id) {
          this.data.config_country_name = this.extractNameById(this.data.config_country_id, this.countries);
        }
        if(!this.data.config_state_name && this.data.config_zone_id) {
          this.data.config_state_name = this.extractNameById(this.data.config_zone_id, this.states);
        }
        if(!this.data.config_city_name && this.data.config_city) {
          this.data.config_city_name = this.extractNameById(this.data.config_city, this.cities);
        }
      },
      countries: function () {
        this.data.config_country_name = this.extractNameById(this.data.config_country_id, this.countries);
      }
      ,states: function () {
        this.data.config_state_name = this.extractNameById(this.data.config_zone_id, this.states);
      },
      cities: function () {
        this.data.config_city_name = this.extractNameById(this.data.config_city, this.cities);
      },
      
    },
    mounted() {
      this.getcountries();
      if(this.DomainId){
        this.getsetting();
      }      
      },
    methods: {
      async getCities(statsid){
        return CountryService.getCities(1,statsid).then(response => {
          this.cities=response.data.data;
        });
      },
     async getStates(countryid){
        return CountryService.getStates(1,countryid).then(response => {
          this.states=response.data.data;
        });
      },
      async getcountries(){
       return CountryService.getcountries().then(response => {
         this.countries=response.data.data;
      });
      },
      extractNameById(needle, hayStack) {
        let val = null;
        val = hayStack.filter(function(element){
          if(element['value'] == Number(needle)) {
            return element['label'];
          }
        });
        if(val.length) {
          return val[0]['label'];
        }
        return null;
      },
      submitForm(formName) {
        this.data.config_country_id = this.data.config_country_name;
        this.data.config_zone_id = this.data.config_state_name;
        this.data.config_city = this.data.config_city_name;        
        this.$refs[formName].validate((valid) => {
          if (valid && !this.DomainId) {
             return SettingService.add(this.data).then(response => {
                this.showAlertAdded=true;
              });
          } else if (valid && this.DomainId) {
            return SettingService.update(this.DomainId, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
              
	getsetting(){
    return SettingService.get(this.DomainId).then(response => {
        this.data = response.data;
        if(this.data.config_country_id){
          this.getStates(this.data.config_country_id);
        }
         if(this.data.config_zone_id){
          this.getCities(this.data.config_zone_id);
        }   
        
      });
	},
     
   }
  }
</script>
<style lang="scss">
.el-select{
  width: 100%;
}
.align-left{
  text-align: left;
}
 .panel {
    text-align: left;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
</style>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.note-label{font-size: 12px;font-weight:700;margin-bottom: 6px;text-transform: capitalize;}
.italic{font-style: italic;color: #a72222;}
</style>
